import { RentalOrderMachineCounterPriceTracking } from "@farmact/model/src/model/RentalOrder";
import { IonButton } from "@ionic/react";
import { useState } from "react";
import { EditRentalOrderMachinePriceModal } from "./EditRentalOrderMachinePriceModal/EditRentalOrderMachinePriceModal";
import "./rentalOrderMachinePriceControl.scss";

type RentalOrderMachinePriceControlProps = {
    priceTracking: RentalOrderMachineCounterPriceTracking;
    onPriceTrackingChange: (updateData: Partial<RentalOrderMachineCounterPriceTracking>) => void;

    disabled?: boolean;
};

export const RentalOrderMachinePriceControl = (props: RentalOrderMachinePriceControlProps) => {
    const [open, setOpen] = useState(false);

    const handleSubmit = (updateData: Partial<RentalOrderMachineCounterPriceTracking>) => {
        setOpen(false);
        props.onPriceTrackingChange(updateData);
    };

    return (
        <>
            <IonButton
                color="light"
                fill="solid"
                className="rental-order-machine-price-control"
                onClick={() => setOpen(true)}
                disabled={props.disabled}>
                Eingeben
            </IonButton>

            <EditRentalOrderMachinePriceModal
                isOpen={open}
                onDismiss={() => setOpen(false)}
                priceTracking={props.priceTracking}
                onSubmit={handleSubmit}
            />
        </>
    );
};
