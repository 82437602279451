import { createContext, useContext } from "react";

export type ToastColor = "danger" | "warning" | "success" | "primary";

export type ToastContextType = {
    onMessage: (message: string, color: ToastColor) => void;
    dismissMessage: () => void;
};

export const ToastContext = createContext<ToastContextType>({
    onMessage: () => {},
    dismissMessage: () => {},
});

export function useToastContext() {
    return useContext(ToastContext);
}
