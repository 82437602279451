import { useCallback, useRef } from "react";
import { ValidationController } from "./validation/useValidation";

type FormValidationConfig = {
    [fieldName: string]: ValidationController;
};

export type UseFormValidation<Config extends FormValidationConfig> = {
    fields: Config;
    validateForm: () => void;
    checkIsFormValid: () => boolean;
    resetForm: () => void;
};

/**
 * Validate multiple form fields at once
 * @param config
 * @returns
 */
export function useFormValidation<Config extends FormValidationConfig>(config: Config): UseFormValidation<Config> {
    const latestConfig = useRef(config);
    latestConfig.current = config;

    const validateForm = useCallback(() => {
        for (const validator of Object.values(latestConfig.current)) {
            validator.validate();
        }
    }, []);

    const checkIsFormValid = useCallback(() => {
        const isValid = Object.values(latestConfig.current).map(validator => validator.validate());
        return isValid.every(Boolean);
    }, []);

    const resetForm = useCallback(() => {
        for (const validator of Object.values(latestConfig.current)) {
            validator.reset();
        }
    }, []);

    return {
        fields: config,
        validateForm,
        checkIsFormValid,
        resetForm,
    };
}
