import { Currency } from "@farmact/model/src/model/Currency";
import { Locale } from "@farmact/model/src/model/Locale";
import dayjs from "dayjs";

function getFormattableLocale(locale: Locale): string {
    switch (locale) {
        case Locale.DE_DE:
            return "de-DE";
        case Locale.DE_CH:
            return "de-CH";
    }
}

function getNumberFormatCurrency(currency: Currency): string {
    switch (currency) {
        case Currency.EUR:
            return "EUR";
        case Currency.CHF:
            return "CHF";
    }
}

type FormatterFn<T> = (value: T) => string;

export type LocaleFormatter<T> = {
    format: FormatterFn<T>;
};

export type LocaleFormatterGenerator<T> = (locale: Locale) => LocaleFormatter<T>;

export function getNumberFormatter(locale: Locale, fractionDigits = 2, minimumFractionDigits?: number) {
    return new Intl.NumberFormat(getFormattableLocale(locale), {
        minimumFractionDigits: minimumFractionDigits ?? fractionDigits,
        maximumFractionDigits: fractionDigits,
    });
}

export function getCurrencyFormatter(locale: Locale, currency: Currency, fractionDigits?: number) {
    return new Intl.NumberFormat(getFormattableLocale(locale), {
        style: "currency",
        currency: getNumberFormatCurrency(currency),
        maximumFractionDigits: fractionDigits,
    });
}

export function getPercentFormatter(locale: Locale, fractionDigits = 2) {
    return new Intl.NumberFormat(getFormattableLocale(locale), {
        style: "percent",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    });
}

export function getAreaFormatter(locale: Locale, fractionDigits = 2) {
    return {
        format: (areaSquareMeters: number) => {
            const isSmallArea = areaSquareMeters < 1000;

            const formatter = getNumberFormatter(locale, fractionDigits);
            return isSmallArea
                ? `${formatter.format(areaSquareMeters)} m²`
                : `${formatter.format(areaSquareMeters / 10000)} ha`;
        },
    };
}

export function getDistanceFormatter(locale: Locale, fractionDigits = 2) {
    return {
        format: (distanceMeters: number) => {
            const isSmallDistance = distanceMeters < 1000;

            const formatter = getNumberFormatter(locale, fractionDigits);
            return isSmallDistance
                ? `${formatter.format(distanceMeters)} m`
                : `${formatter.format(distanceMeters / 1000)} km`;
        },
    };
}

export function getDateFormatter(locale: Locale) {
    return {
        format: (date: Date | dayjs.Dayjs) => {
            switch (locale) {
                case Locale.DE_DE:
                case Locale.DE_CH:
                    return dayjs(date).format("DD.MM.YYYY");
                default:
                    return dayjs(date).format("DD.MM.YYYY");
            }
        },
    };
}

export function getDateTimeFormatter(locale: Locale) {
    return {
        format: (date: Date | dayjs.Dayjs) => {
            switch (locale) {
                case Locale.DE_DE:
                case Locale.DE_CH:
                    return dayjs(date).format("DD.MM.YYYY HH:mm");
                default:
                    return dayjs(date).format("DD.MM.YYYY HH:mm");
            }
        },
    };
}

export function getCustomDateFormatter(locale: Locale, options?: Intl.DateTimeFormatOptions) {
    return new Intl.DateTimeFormat(getFormattableLocale(locale), options);
}

export function getSingularPluralTextFormatter(singular: string, plural: string) {
    return (count: number) => {
        return count === 1 ? singular : plural;
    };
}

// `useLocaleFormatter` compatible formatters

/**
 * @param currency defaults to EUR
 * @param fractionDigits defaults to 2
 */
export const currencyFormatterGenerator = (
    currency = Currency.EUR,
    fractionDigits = 2
): LocaleFormatterGenerator<number> => {
    return locale => {
        return getCurrencyFormatter(locale, currency, fractionDigits);
    };
};

export const dateFormatterGenerator: LocaleFormatterGenerator<Date | dayjs.Dayjs> = locale => getDateFormatter(locale);

export const areaFormatterGenerator = (fractionDigits = 2): LocaleFormatterGenerator<number> => {
    return locale => getAreaFormatter(locale, fractionDigits);
};

export const distanceFormatterGenerator = (fractionDigits = 2): LocaleFormatterGenerator<number> => {
    return locale => getDistanceFormatter(locale, fractionDigits);
};

export const numberFormatterGenerator = (
    fractionDigits = 2,
    minimumFractionDigits?: number
): LocaleFormatterGenerator<number> => {
    return locale => getNumberFormatter(locale, fractionDigits, minimumFractionDigits);
};

export const percentFormatterGenerator = (fractionDigits = 2): LocaleFormatterGenerator<number> => {
    return locale => getPercentFormatter(locale, fractionDigits);
};

type FormatListOptions = {
    /**
     * @default 3
     */
    cap: number;
    /**
     * @default "-"
     */
    empty: string;
    /**
     * Used as postfix if there is exactly 1 remaining item
     * @default "weitere"
     */
    postfixSingular: string;
    /**
     * Used as postfix if there are more than 1 remaining items
     * @default "weitere"
     */
    postfixPlural: string;
};
const defaultFormatListOptions: FormatListOptions = {
    cap: 3,
    empty: "-",
    postfixSingular: "weitere",
    postfixPlural: "weitere",
};

export function formatList(list: string[], options: Partial<FormatListOptions> = {}): string {
    const mergedOptions = {
        ...defaultFormatListOptions,
        ...options,
    };

    if (list.length === 0) {
        return mergedOptions.empty;
    }

    if (list.length > mergedOptions.cap) {
        const part = list.slice(0, mergedOptions.cap);
        const rest = list.slice(mergedOptions.cap);

        const spFormatter = getSingularPluralTextFormatter(mergedOptions.postfixSingular, mergedOptions.postfixPlural);

        return `${part.join(", ")} und ${rest.length} ${spFormatter(rest.length)}`;
    }

    return list.join(", ");
}
