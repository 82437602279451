import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import dayjs from "dayjs";
import { useRentalOrderDetailsPageContext } from "@/components/rentalOrders/RentalOrderDetailsPage/RentalOrderDetailsPageContext";
import { useToastContext } from "@/components/ToastContext";
import { useFormValidation } from "@/util/customHooks/useFormValidation";
import { SingleValidationError, useValidation } from "@/util/customHooks/validation/useValidation";
import { EditRentalOrderMachinePriceState } from "./EditRentalOrderMachinePriceModal";

type UseEditRentalOrderMachinePriceModalValidationReturn = {
    /**
     * Validate state and show error messages
     */
    validate: () => boolean;
    reset: () => void;
    errors: Partial<EditRentalOrderMachinePriceErrors>;
};

export function useEditRentalOrderMachinePriceModalValidation(
    state: EditRentalOrderMachinePriceState
): UseEditRentalOrderMachinePriceModalValidationReturn {
    const { onMessage: showMessage } = useToastContext();
    const { rentalOrder } = useRentalOrderDetailsPageContext();

    const validation = useFormValidation({
        startDate: useValidation([rentalOrder.status, state.startDate] as const, ([status, startDate]) => {
            if (status !== OrderStatus.DONE) {
                return;
            }
            return startDate === null || Number.isNaN(+startDate) ? "invalid_start_date" : undefined;
        }),
        startValue: useValidation([rentalOrder.status, state.startValue] as const, ([status, startValue]) => {
            if (status !== OrderStatus.DONE) {
                return;
            }
            return startValue === null ? "invalid_start_value" : undefined;
        }),
        endDateBeforeStartDate: useValidation([state.endDate, state.startDate] as const, ([endDate, startDate]) => {
            if (!startDate || !endDate) {
                return;
            }

            return dayjs(endDate).isBefore(startDate) ? "invalid_end_date" : undefined;
        }),
        endDate: useValidation([rentalOrder.status, state.endDate] as const, ([status, endDate]) => {
            if (status !== OrderStatus.DONE) {
                return;
            }
            return endDate === null || Number.isNaN(+endDate) ? "invalid_end_date" : undefined;
        }),
        endValue: useValidation([rentalOrder.status, state.endValue] as const, ([status, endValue]) => {
            if (status !== OrderStatus.DONE) {
                return;
            }
            return endValue === null ? "invalid_end_value" : undefined;
        }),
    });

    const validate = (): boolean => {
        validation.validateForm();

        if (!validation.fields.startDate.validate()) {
            showMessage("Bitte gib ein Startdatum ein.", "warning");
            return false;
        }
        if (!validation.fields.startValue.validate()) {
            showMessage("Bitte gib ein Startwert ein.", "warning");
            return false;
        }
        if (!validation.fields.endDate.validate()) {
            showMessage("Bitte gib ein Enddatum ein.", "warning");
            return false;
        }
        if (!validation.fields.endDateBeforeStartDate.validate()) {
            showMessage("Bitte gib ein Enddatum vor dem Startdatum ein.", "warning");
            return false;
        }
        if (!validation.fields.endValue.validate()) {
            showMessage("Bitte gib ein Endwert ein.", "warning");
            return false;
        }

        return true;
    };

    const errors: Partial<EditRentalOrderMachinePriceErrors> = {
        startDate: validation.fields.startDate.error,
        startValue: validation.fields.startValue.error,
        endDate: validation.fields.endDate.error,
        endValue: validation.fields.endValue.error,
    };

    return {
        validate,
        reset: validation.resetForm,
        errors,
    };
}

type EditRentalOrderMachinePriceErrors = {
    [Key in keyof EditRentalOrderMachinePriceState]: SingleValidationError;
};
