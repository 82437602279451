export enum OrderStatus {
    DRAFT = "DRAFT",
    ASSIGNED = "ASSIGNED",
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE",
    CHECKED = "CHECKED",
    BILLED = "BILLED",
}

/**
 * @deprecated
 */
enum LegacyOrderStatus {
    DRAFT = "Entwurf",
    ASSIGNED = "Zugewiesen",
    IN_PROGRESS = "In Arbeit",
    DONE = "Erledigt",
    BILLED = "Bezahlt",
}

export function getOrderStatusName(status: OrderStatus | LegacyOrderStatus): string {
    switch (status) {
        case LegacyOrderStatus.ASSIGNED:
        case OrderStatus.ASSIGNED:
            return "Zugewiesen";
        case LegacyOrderStatus.DRAFT:
        case OrderStatus.DRAFT:
            return "Entwurf";
        case LegacyOrderStatus.DONE:
        case OrderStatus.DONE:
            return "Erledigt";
        case LegacyOrderStatus.IN_PROGRESS:
        case OrderStatus.IN_PROGRESS:
            return "In Arbeit";
        case LegacyOrderStatus.BILLED:
        case OrderStatus.BILLED:
            return "In Rechnung gestellt";
        case OrderStatus.CHECKED:
            return "Kontrolliert";
    }
}

export type OrderStatusColors = {
    [status in OrderStatus]: string;
};
