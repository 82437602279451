import { RentalOrder } from "@farmact/model/src/model/RentalOrder";
import { createContext, useContext } from "react";

export type RentalOrderDetailsPageContextType = {
    rentalOrder: RentalOrder;
};
export const RentalOrderDetailsPageContext = createContext<RentalOrderDetailsPageContextType | null>(null);

export function useRentalOrderDetailsPageContext() {
    const context = useContext(RentalOrderDetailsPageContext);
    if (context === null) {
        throw new Error(
            "useRentalOrderDetailsPageContext must be used inside a <RentalOrderDetailsPageContext.Provider>"
        );
    }

    return context;
}
