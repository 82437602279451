import { Machine } from "@farmact/model/src/model/Machine";
import { useOrganizationContext } from "@/components/organization/context/useOrganizationContext";
import "./rentalOrderPriceTrackingsMachineGroupHeader.scss";

type RentalOrderPriceTrackingsMachineGroupHeaderProps = {
    machineId: Machine["id"];
};

export const RentalOrderPriceTrackingsMachineGroupHeader = (
    props: RentalOrderPriceTrackingsMachineGroupHeaderProps
) => {
    const { machines } = useOrganizationContext();

    const machine = machines.find(machine => {
        return machine.id === props.machineId;
    });

    return (
        <div className="rental-order-price-trackings-machine-group-header">
            <h3 className="rental-order-price-trackings-machine-group-header__title">{machine?.name ?? "Maschine"}</h3>
        </div>
    );
};
