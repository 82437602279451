import clsx from "clsx";
import { ComponentPropsWithRef, forwardRef } from "react";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import "./input.scss";

type InputOwnProps = {
    fullWidth?: boolean;
    error?: SingleValidationError;
};
type InputProps = InputOwnProps & Omit<ComponentPropsWithRef<"input">, keyof InputOwnProps>;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, forwardedRef) => {
    const { id: controlledId, fullWidth = false, disabled: controlledDisabled, error, ...restProps } = props;

    const { id: uncontrolledId, disabled: uncontrolledDisabled, state } = useFormControlContext();

    return (
        <input
            {...restProps}
            ref={forwardedRef}
            id={controlledId || uncontrolledId}
            disabled={controlledDisabled || uncontrolledDisabled}
            className={clsx(
                "farmact-input",
                {
                    "farmact-input--full-width": fullWidth,
                    //
                    "farmact-input--state-normal": state === "normal",
                    "farmact-input--state-warning": state === "warning",
                    "farmact-input--state-error": state === "error" || error,
                },
                props.className
            )}
        />
    );
});
