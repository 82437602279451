import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPlatform } from "@ionic/core";
import { DatePickerInput, DateInput as MatineDateInput } from "@mantine/dates";
import clsx from "clsx";
import dayjs from "dayjs";
import { CSSProperties, useState } from "react";
import useResizeObserver from "use-resize-observer";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import { getPortalTarget } from "@/util/overlayUtils";
import { DateLike, isValidDate } from "@/util/timeUtils";
import "./dateInput.scss";

export type DateInputProps = {
    value?: DateLike | null;
    onChange?: (value: Date | null) => void;

    min?: DateLike;
    max?: DateLike;
    error?: SingleValidationError;
    /**
     * @default "Datum auswählen"
     */
    placeholder?: string;
    /**
     * @default false
     */
    clearable?: boolean;

    className?: string;
    id?: string;
    disabled?: boolean;
};

export const DateInput = (props: DateInputProps) => {
    const {
        placeholder = "Datum auswählen",
        clearable = false,
        id: controlledId,
        disabled: controlledDisabled,
        ...restProps
    } = props;

    const { id: uncontrolledId, disabled: uncontrolledDisabled, state } = useFormControlContext();

    const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
    const { width = 0 } = useResizeObserver<HTMLElement>({
        ref: triggerRef,
        box: "border-box",
    });

    const isMobile = isPlatform("mobile");

    const convertedDate = restProps.value ? dayjs(restProps.value).toDate() : null;
    const validatedDate = convertedDate && isValidDate(convertedDate) ? convertedDate : null;

    const sharedProps = {
        ref: setTriggerRef,
        unstyled: true,
        id: controlledId || uncontrolledId,
        disabled: controlledDisabled || uncontrolledDisabled,
        valueFormat: "DD.MM.YYYY",
        value: validatedDate,
        onChange: restProps.onChange,
        placeholder,
        clearable,
        minDate: restProps.min ? dayjs(restProps.min).toDate() : undefined,
        maxDate: restProps.max ? dayjs(restProps.max).toDate() : undefined,
        rightSection: <FontAwesomeIcon icon={faCalendarDays} />,
        withAsterisk: false,
        className: clsx("date-input", restProps.className, {
            "date-input--state-normal": state === "normal",
            "date-input--state-warning": state === "warning",
            "date-input--state-error": state === "error" || props.error,
        }),
        classNames: {
            wrapper: "date-input__wrapper",
            input: "date-input__input",
            section: "date-input__right-section",
            calendarHeader: "date-input__calendar-header",
            calendarHeaderControl: "date-input__calendar-header-control",
            calendarHeaderLevel: "date-input__calendar-header-level",
            // month view
            month: "date-input__month",
            weekday: "date-input__weekday",
            monthCell: "date-input__month-cell",
            day: "date-input__day",
            // months view
            monthsList: "date-input__list",
            monthsListCell: "date-input__list-cell",
            monthsListControl: "date-input__list-control",
            // years view
            yearsList: "date-input__list",
            yearsListCell: "date-input__list-cell",
            yearsListControl: "date-input__list-control",
        },
        popoverProps: {
            withinPortal: true,
            classNames: {
                dropdown: "date-input__dropdown",
            },
            styles: {
                dropdown: {
                    ["--date-input-trigger-width"]: `${width}px`,
                } as CSSProperties,
            },
            portalProps: {
                target: getPortalTarget(triggerRef),
            },
        },
    };

    if (isMobile) {
        return <DatePickerInput {...sharedProps} />;
    }

    return <MatineDateInput {...sharedProps} />;
};
