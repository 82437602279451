import { MachineCounterType } from "@farmact/model/src/model/MachineCounterTracking";
import { getSingularPluralTextFormatter } from "@farmact/utils/src/formatters";

export function getMachineCounterTypeName(type: MachineCounterType): string {
    switch (type) {
        case MachineCounterType.OPERATING_HOURS:
            return "Betriebsstunden";
        case MachineCounterType.HECTARE:
            return "Hektarzähler";
        case MachineCounterType.DRUM_HOURS:
            return "Trommelzähler";
        case MachineCounterType.KILOMETER:
            return "Kilometerzähler";
        case MachineCounterType.LOADS:
            return "Fuhrenzähler";
        case MachineCounterType.BALES:
            return "Ballenzähler";
        case MachineCounterType.DIESEL:
            return "Dieselzähler";
    }
}

export function getMachineCounterUnitName(type: MachineCounterType, count = 0): string {
    switch (type) {
        case MachineCounterType.OPERATING_HOURS:
            return getSingularPluralTextFormatter("Stunde", "Stunden")(count);
        case MachineCounterType.HECTARE:
            return "Hektar";
        case MachineCounterType.DRUM_HOURS:
            return getSingularPluralTextFormatter("Trommelstunde", "Trommelstunden")(count);
        case MachineCounterType.KILOMETER:
            return "Kilometer";
        case MachineCounterType.LOADS:
            return getSingularPluralTextFormatter("Fuhre", "Fuhren")(count);
        case MachineCounterType.BALES:
            return "Ballen";
        case MachineCounterType.DIESEL:
            return "Liter";
    }
}

export function getMachineCounterUnitShortName(type: MachineCounterType, count = 0): string {
    switch (type) {
        case MachineCounterType.OPERATING_HOURS:
            return "h";
        case MachineCounterType.HECTARE:
            return "ha";
        case MachineCounterType.DRUM_HOURS:
            return getSingularPluralTextFormatter("Trommelstunde", "Trommelstunden")(count);
        case MachineCounterType.KILOMETER:
            return "km";
        case MachineCounterType.LOADS:
            return getSingularPluralTextFormatter("Fuhre", "Fuhren")(count);
        case MachineCounterType.BALES:
            return "Ballen";
        case MachineCounterType.DIESEL:
            return "l";
    }
}
