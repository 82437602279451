import { RentalOrderCustomCounterPriceTracking } from "@farmact/model/src/model/RentalOrder";
import { ChangeEvent } from "react";
import { Input } from "@/components/inputs/Input/Input";
import "./rentalOrderCustomPriceControl.scss";

type RentalOrderCustomPriceControlProps = {
    priceTracking: RentalOrderCustomCounterPriceTracking;
    onPriceTrackingChange: (updateData: Partial<RentalOrderCustomCounterPriceTracking>) => void;

    disabled?: boolean;
};

export function RentalOrderCustomPriceControl(props: RentalOrderCustomPriceControlProps) {
    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.valueAsNumber;
        props.onPriceTrackingChange({
            value: Number.isNaN(value) ? null : value,
        });
    };

    return (
        <Input
            className="rental-order-custom-price-control"
            type="number"
            placeholder="Wert eingeben"
            value={props.priceTracking.value ?? ""}
            onChange={handleAmountChange}
            disabled={props.disabled}
        />
    );
}
